/* eslint-disable react/display-name */
import getConfig from "next/config"
import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"

const { publicRuntimeConfig } = getConfig()

interface SEOProps {
  title?: any
  titleSuffix?: boolean
  description?: Object | string
  ogDescription?: Object | string
  ogImage?: Object | string
  ogType?: string
  noIndex?: boolean
  noIndexButFollow?: boolean
}

const SEO: React.FC<SEOProps> = React.memo(
  ({
    ogDescription,
    description,
    title,
    titleSuffix = true,
    ogImage,
    ogType = "website",
    noIndex = publicRuntimeConfig.APP_ENV !== "production",
    noIndexButFollow = false,
  }: SEOProps) => {
    const locProps = {
      description,
      ogDescription,
      title,
      titleSuffix,
      ogImage,
      noIndex,
      noIndexButFollow,
      ogType,
    }
    const router = useRouter()
    const host =
      publicRuntimeConfig.APP_ENV === "production" ? "https://www.poznejmecesko.cz" : "https://www.poznejmecesko.cz"

    const { asPath } = router

    const defaultValues = {
      title: "Poznejme Česko",
      description: "TODO",
      ogDescription: "TODO",
      ogImage: `TODO`,
      ogSiteName: "Poznejme Česko",
      email: "TODO",
    }

    const finalProps: any = Object.keys(locProps).reduce((acc, propKey) => {
      acc[propKey] = locProps[propKey] || defaultValues[propKey]
      return acc
    }, {})

    let desc = finalProps.description.replace(/(\r\n|\n|\r)/gm, "").replace(/(<([^>]+)>)/gi, "")
    let ogDesc = finalProps.ogDescription.replace(/(\r\n|\n|\r)/gm, "").replace(/(<([^>]+)>)/gi, "")

    const maxDescLength = 170
    desc = desc.length > maxDescLength ? `${desc.substring(0, maxDescLength - 3)}...` : desc
    ogDesc = ogDesc.length > maxDescLength ? `${ogDesc.substring(0, maxDescLength - 3)}...` : ogDesc

    const titleComposed = `${finalProps.title} ${finalProps.titleSuffix ? " | PoznejmeČesko.cz" : ""}`

    return (
      <Head>
        <title>{titleComposed}</title>
        {finalProps.noIndex === true && <meta name="robots" content="noindex, nofollow" />}
        {finalProps.noIndexButFollow === true && publicRuntimeConfig.APP_ENV === "production" && (
          <meta name="robots" content="noindex, follow" />
        )}

        <meta name="description" content={desc} />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="author" content="TODO" />

        <meta property="og:image" name="image" content={finalProps.ogImage} />
        <meta property="og:description" content={ogDesc} />
        <meta property="og:title" content={finalProps.title} />
        <meta property="og:type" content={finalProps.ogType} />
        <meta property="og:site_name" content="Poznejme Česko" />
        <meta property="og:url" content={`${host}${asPath?.split("?")[0]}`} />
        <meta property="og:email" content={finalProps.email} />
        {/* <meta property='fb:app_id' content={publicRuntimeConfig.FACEBOOK_APP_ID} /> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={finalProps.title} />
        <meta name="twitter:site" content="@TODO" />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:creator" content="TODO" />
        <meta name="twitter:image" content={finalProps.ogImage} />

        {/* <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
        <link rel='manifest' href='/favicon/site.webmanifest' />
        <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color='#1f2549' /> */}
        <meta name="msapplication-TileColor" content="#edeae3" />
        <meta name="theme-color" content="#ffffff" />

        {/* <meta name='google-signin-scope' content='profile email' /> */}
        {/* <meta name='google-signin-client_id' content={publicRuntimeConfig.GOOGLE_CLIENT_ID} /> */}
      </Head>
    )
  },
)

export default SEO
